import axios from "axios";
import {
  login,
  getmess,
  bindsubmit,
  searchDoc
} from "../../api/index";
import {
  api,
  config
} from "../../utils/urllist";
export default {
  data() {
    var checkName = (rule, value, callback) => {
      let phtest = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
      if (!value) {
        return callback(new Error("请填入手机号码"));
      } else if (!phtest.test(value)) {
        return callback(new Error("请填入正确的手机号码"));
      } else if (value.toString().length !== 11) {
        return callback(new Error("电话号码必须是11位数字"));
      } else {
        callback();
      }
    };
    var checkYzm = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    var checkHosp = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入医院名称"));
      } else {
        callback();
      }
    };
    var checkServeId = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入科室"));
      } else {
        callback();
      }
    };
    var checkUsername = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入姓名"));
      } else {
        callback();
      }
    };
    var checkT = (rule, value, callback) => {
      if (value === false) {
        return callback(new Error("请勾选实名注册"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        name: "",
        verification: "", //绑定输入验证码框框
        registerHospitalName: "", //医院名称
        organizationName: "", //科室
        username: "", //姓名
        checktrue: false,
      },
      ruleFormbyPh: {
        name: "",
        checktrue: false,
        verification: "",
      },
      // logining: false,
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */ ,
      auth_time: 0 /*倒计时 计数器*/ ,
      rules: {
        verification: [{
          validator: checkYzm,
          trigger: "blur"
        }],
        name: [{
          validator: checkName,
          trigger: "blur"
        }],
        registerHospitalName: [{
          validator: checkHosp,
          trigger: "blur"
        }],
        organizationName: [{
          validator: checkServeId,
          trigger: "blur"
        }],
        username: [{
          validator: checkUsername,
          trigger: "blur"
        }],
        checktrue: [{
          validator: checkT,
          trigger: "change"
        }],
      },
      rules2: {
        verification: [{
          validator: checkYzm,
          trigger: "blur"
        }],
        name: [{
          validator: checkName,
          trigger: "blur"
        }],
      },
    };
  },
  methods: {
    toLogin() {
      this.$router.push("/login")
    },
    //  验证
    async getAuthCode(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validateField("name", async (nameErro) => {
        if (nameErro) {
          return false;
        } else {
          let sdata = new FormData();
          sdata.append("phone", this.$refs[formName].model.name);
          sdata.append("yzm", this.$refs[formName].model.verification);
          sdata.append("countCache", "regist");
          sdata.append("smsCache", "dx");
          axios
            .post(
              api.urlHead + api.sendmsm,
              sdata,
              config
            )
            .then(async (res) => {
              if (res.data.msg === "成功") {
                this.$message.success({
                  message: "发送成功",
                  duration: 1000,
                });
                this.sendAuthCode = false;
                //设置倒计时秒
                this.auth_time = 60;
                var auth_timetimer = setInterval(() => {
                  this.auth_time--;
                  if (this.auth_time <= 0) {
                    this.sendAuthCode = true;
                    clearInterval(auth_timetimer);
                  }
                }, 1000);
              } else {
                this.$message.error({
                  message: res.data.msg,
                  duration: 1000,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.logining = true;
          let bdata = {
            phone: this.$refs[formName].model.name,
            yzm: this.$refs[formName].model.verification,
            registerHospitalName: this.$refs[formName].model.registerHospitalName,
            organizationName: this.$refs[formName].model.organizationName,
            name: this.$refs[formName].model.username,
            isRegister: "1",
          };

          let fdata = new FormData();
          fdata.append("phone", this.$refs[formName].model.name);
          fdata.append("yzm", this.$refs[formName].model.verification);
          fdata.append("countCache", "bind");
          fdata.append("smsCache", "dx");
          axios
            .post(
              api.urlHead + api.smscheck,
              fdata,
              config
            )
            .then(async (res) => {
              let result = res.data;
              if (result.msg === "验证码错误") {
                this.$message.error({
                  message: result.msg,
                  duration: 1000,
                });
              } else {
                const sresult = await bindsubmit(bdata);
                if (sresult.code === 403) {
                  this.$message.error({
                    message: sresult.msg,
                    duration: 1000,
                  });
                  this.$router.push("/login");
                } else if (sresult.code === 200) {
                  this.$message.success({
                    message: sresult.msg,
                    duration: 1000,
                  });
                  let databyph = {
                    phone: this.$refs[formName].model.name
                  }
                  const userinfo = await searchDoc(databyph)
                  if (userinfo.code === 200) {
                    let userdata = {
                      name: userinfo.data.records[0].name,
                      nickName: userinfo.data.records[0].nickName,
                      headImgUrl: userinfo.data.records[0].headImgUrl,
                      phone: userinfo.data.records[0].phone,
                      isRealName: userinfo.data.records[0].isRealName,
                      agoraUid: userinfo.data.records[0].agoraUid,
                    }
                    sessionStorage.setItem("userinfo", JSON.stringify(userdata));
                  }
                  let sesurl = JSON.parse(sessionStorage.getItem("urlAll"))
                  if (!sesurl || sesurl[0].path === "/register" ) {
                    this.$router.push('/')
                  } else {
                    this.$router.push({
                      path: sesurl[0].path,
                      query: sesurl[0].query
                    });
                  }
                } else {
                  this.$message.error({
                    message: sresult.msg,
                    duration: 1000,
                  });
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("submit err");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};